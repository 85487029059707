import React, { useState } from 'react';
import { User, Upload } from 'lucide-react';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref, update } from 'firebase/database';
import { storage, database, auth } from '../../firebaseConfig';

const ProfileImageUpload = ({ photoURL, onPhotoUpdated, size = "medium" }) => {
  const [uploading, setUploading] = useState(false);

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Nessun file selezionato.");
      return;
    }
  
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      alert("Per favore carica un'immagine in formato JPG, PNG o GIF.");
      return;
    }
  
    if (file.size > 5 * 1024 * 1024) {
      alert("L'immagine deve essere inferiore a 5MB.");
      return;
    }
  
    try {
      setUploading(true);
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error("Utente non autenticato.");
  
      const imageRef = storageRef(storage, `profile-photos/${userId}`);
      await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(imageRef);
  
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, {
        photoURL: downloadURL,
      });
  
      onPhotoUpdated(downloadURL);
    } catch (error) {
      console.error("Errore durante il caricamento:", error);
      alert("Errore durante il caricamento. Riprova più tardi.");
    } finally {
      setUploading(false);
    }
  };

  const sizeClasses = {
    small: "w-12 h-12",
    medium: "w-24 h-24",
    large: "w-32 h-32"
  };

  return (
    <div className="relative">
      <div className={`${sizeClasses[size]} bg-gray-100 rounded-full overflow-hidden`}>
        {photoURL ? (
          <img 
            src={photoURL} 
            alt="Profile" 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <User className={`${size === 'small' ? 'w-6 h-6' : 'w-12 h-12'} text-gray-400`} />
          </div>
        )}
      </div>
      <label className="absolute bottom-0 right-0 cursor-pointer">
        <input
          type="file"
          accept="image/*"
          onChange={handlePhotoUpload}
          className="hidden"
          disabled={uploading}
        />
        <div className="bg-white rounded-full p-2 shadow-md hover:bg-gray-50">
          <Upload className="w-4 h-4 text-gray-600" />
        </div>
      </label>
    </div>
  );
};

export default ProfileImageUpload; 