import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { User, Save, Edit2 } from 'lucide-react';
import Sidebar from './Sidebar';
import ProfileImageUpload from '../shared/ProfileImageUpload';

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userRef = ref(database, `users/${userId}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setProfile(data);
        setEditedProfile(data);
      });
    }
  }, []);

  const handleSave = async () => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, editedProfile);
      setIsEditing(false);
      setProfile(editedProfile);
    }
  };

  if (!profile) {
    return (
      <div className="flex min-h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center text-lg text-gray-600">
            <div className="w-24 h-24 mx-auto mb-4">
              <img src="/loading.svg" alt="Loading" className="w-full h-full" />
            </div>
            Caricamento...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 p-4 sm:p-8 ml-0 lg:ml-52">
        <div className="max-w-4xl mx-auto">
          <header className="flex justify-end lg:justify-between items-center mb-8">
            <h1 className="hidden lg:block text-3xl font-serif text-emerald-800 font-semibold">Il Tuo Profilo</h1>
            {!isEditing ? (
              <button 
                onClick={() => setIsEditing(true)}
                className="text-coral-500 hover:text-coral-600 flex items-center gap-2"
              >
                <Edit2 className="w-5 h-5" />
                Modifica profilo
              </button>
            ) : (
              <button 
                onClick={handleSave}
                className="text-coral-500 hover:text-coral-600 flex items-center gap-2"
              >
                <Save className="w-5 h-5" />
                Salva modifiche
              </button>
            )}
          </header>

          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <div className="flex items-center gap-6 mb-8 flex-col sm:flex-row">
              <ProfileImageUpload 
                photoURL={profile?.photoURL}
                onPhotoUpdated={(url) => setEditedProfile(prev => ({ ...prev, photoURL: url }))}
                size="medium"
              />
              <div className="text-center sm:text-left mt-4 sm:mt-0">
                <h2 className="text-xl font-serif text-gray-800">{profile.name} {profile.surname}</h2>
                <p className="text-gray-600">{profile.email}</p>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {isEditing ? (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2 font-semibold">Nome</label>
                    <input
                      type="text"
                      value={editedProfile.name}
                      onChange={(e) => setEditedProfile({...editedProfile, name: e.target.value})}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Cognome</label>
                    <input
                      type="text"
                      value={editedProfile.surname}
                      onChange={(e) => setEditedProfile({...editedProfile, surname: e.target.value})}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Telefono</label>
                    <input
                      type="tel"
                      value={editedProfile.phone}
                      onChange={(e) => setEditedProfile({...editedProfile, phone: e.target.value})}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Fascia oraria preferita</label>
                    <select
                      value={editedProfile.preferredTime}
                      onChange={(e) => setEditedProfile({...editedProfile, preferredTime: e.target.value})}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                    >
                      <option value="mattina">Mattina</option>
                      <option value="pomeriggio">Pomeriggio</option>
                      <option value="sera">Sera</option>
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-gray-600"><span className="font-medium font-semibold">Nome:</span> {profile.name}</p>
                  <p className="text-gray-600"><span className="font-medium font-semibold">Cognome:</span> {profile.surname}</p>
                  <p className="text-gray-600"><span className="font-medium font-semibold">Email:</span> {profile.email}</p>
                  <p className="text-gray-600"><span className="font-medium font-semibold">Telefono:</span> {profile.phone}</p>
                  <p className="text-gray-600"><span className="font-medium font-semibold">Tipo di terapia:</span> {profile.therapyType}</p>
                  <p className="text-gray-600"><span className="font-medium font-semibold">Fascia oraria preferita:</span> {profile.preferredTime}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
