import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, database, storage } from '../../firebaseConfig';
import { User, Save, Edit2, Upload } from 'lucide-react';
import Sidebar from './Sidebar';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';

const Tprofile = () => {
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userRef = ref(database, `users/${userId}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setProfile(data);
        setEditedProfile(data);
        setPhotoURL(data.photoURL);
      });
    }
  }, []);

  const handleInputChange = (field, value) => {
    setEditedProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("No file selected.");
      return;
    }
  
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      alert("Please upload an image in JPG, PNG, or GIF format.");
      return;
    }
  
    if (file.size > 5 * 1024 * 1024) {
      alert("The image must be less than 5MB.");
      return;
    }
  
    try {
      setUploading(true);
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error("User not authenticated.");
  
      const imageRef = storageRef(storage, `profile-photos/${userId}`);
      const snapshot = await uploadBytes(imageRef, file);
      console.log("File uploaded successfully:", snapshot);
  
      const downloadURL = await getDownloadURL(imageRef);
      console.log("Download URL:", downloadURL);
  
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, {
        ...editedProfile,
        photoURL: downloadURL,
      });
  
      setPhotoURL(downloadURL);
      setEditedProfile((prev) => ({ ...prev, photoURL: downloadURL }));
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Error during upload. Please try again later.");
    } finally {
      setUploading(false);
    }
  };

  const handleSave = async () => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      try {
        const userRef = ref(database, `users/${userId}`);
        await update(userRef, editedProfile);
        setIsEditing(false);
        setProfile(editedProfile);
      } catch (error) {
        console.error('Error saving profile:', error);
        alert('Errore durante il salvataggio del profilo. Riprova più tardi.');
      }
    }
  };

  if (!profile) {
    return (
      <div className="flex min-h-screen w-full bg-gray-50">
        {/* Sidebar - Hidden on mobile, shown on desktop */}
        <div className="hidden md:block fixed inset-y-0 left-0 w-[280px] bg-white border-r border-gray-200 z-20">
          <Sidebar />
        </div>

        {/* Mobile Sidebar */}
        <div className="md:hidden">
          <Sidebar />
        </div>

        <div className="flex-1 w-full md:pl-[280px] flex items-center justify-center">
          <div className="text-center text-lg text-gray-600">
            <div className="w-24 h-24 mx-auto mb-4">
              <img src="/loading.svg" alt="Loading" className="w-full h-full" />
            </div>
            Caricamento...
          </div>
        </div>
      </div>
    );
  }

  const renderField = (label, field, type = 'text', placeholder = '', hint = '') => {
    if (isEditing) {
      return (
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            {label}
            {hint && <span className="text-gray-500 text-xs ml-1">({hint})</span>}
          </label>
          {type === 'textarea' ? (
            <textarea
              value={editedProfile[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              placeholder={placeholder}
              className="w-full p-3 border border-gray-200 rounded-lg min-h-[100px] text-sm focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
            />
          ) : (
            <input
              type={type}
              value={editedProfile[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              placeholder={placeholder}
              className="w-full p-3 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
            />
          )}
        </div>
      );
    }
    return (
      <div className="space-y-1">
        <p className="text-sm font-medium text-gray-700">{label}</p>
        <p className="text-sm text-gray-600">{profile[field] || '-'}</p>
      </div>
    );
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      {/* Sidebar - Hidden on mobile, shown on desktop */}
      <div className="hidden md:block fixed inset-y-0 left-0 w-[280px] bg-white border-r border-gray-200 z-20">
        <Sidebar />
      </div>

      {/* Mobile Sidebar */}
      <div className="md:hidden">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="flex-1 w-full md:pl-[280px]">
        <div className="w-full p-4 md:p-8">
          {/* Header */}
          <header className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 bg-white rounded-xl p-4 md:p-6 shadow-sm w-full mt-16 md:mt-0">
            <div className="flex items-center gap-4 w-full md:w-auto">
              <div className="w-12 md:w-14 h-12 md:h-14 rounded-full overflow-hidden bg-gray-100 ring-2 ring-emerald-500/20">
                {photoURL ? (
                  <img 
                    src={photoURL} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <User className="w-6 md:w-7 h-6 md:h-7 text-gray-400" />
                  </div>
                )}
              </div>
              <div>
                <h1 className="text-xl md:text-2xl font-serif text-emerald-800 font-semibold">
                  Il Tuo Profilo
                </h1>
                <p className="text-gray-500 text-sm">
                  {profile.name} {profile.surname}
                </p>
              </div>
            </div>
            <button
              onClick={isEditing ? handleSave : () => setIsEditing(true)}
              className="mt-4 md:mt-0 w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
            >
              {isEditing ? (
                <>
                  <Save className="w-4 h-4" />
                  <span>Salva modifiche</span>
                </>
              ) : (
                <>
                  <Edit2 className="w-4 h-4" />
                  <span>Modifica profilo</span>
                </>
              )}
            </button>
          </header>

          <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
            <CardHeader className="border-b border-gray-100">
              <CardTitle className="text-lg md:text-xl font-serif text-emerald-800 font-semibold flex items-center gap-2">
                <User className="w-5 h-5" />
                Informazioni Personali
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <div className="flex items-center gap-6 mb-8 flex-col sm:flex-row">
                <div className="relative">
                  <div className="w-24 h-24 bg-gray-100 rounded-full overflow-hidden">
                    {photoURL ? (
                      <img 
                        src={photoURL} 
                        alt="Profile" 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <User className="w-12 h-12 text-gray-400" />
                      </div>
                    )}
                  </div>
                  {isEditing && (
                    <div className="absolute bottom-0 right-0">
                      <label className="cursor-pointer">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handlePhotoUpload}
                          className="hidden"
                          disabled={uploading}
                        />
                        <div className="bg-white rounded-full p-2 shadow-md hover:bg-gray-50">
                          <Upload className="w-4 h-4 text-gray-600" />
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                <div className="text-center sm:text-left">
                  <h2 className="text-xl font-serif text-gray-800">{profile.name} {profile.surname}</h2>
                  <p className="text-gray-600">{profile.email}</p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {renderField('Nome', 'name', 'text', 'Il tuo nome', 'Inserisci il tuo primo nome')}
                {renderField('Cognome', 'surname', 'text', 'Il tuo cognome', 'Inserisci il tuo cognome')}
                {renderField('Email', 'email', 'email', 'La tua email')}
                {renderField('Approccio', 'approach', 'text', 'Il tuo approccio professionale', 'specificare l\'orientamento, es. psicodinamico, psicoanalitico, cognitivo-comportamentale...')}
                {renderField('Dove vive', 'location', 'text', 'La tua città', 'indicare lo stato e la città')}
                {renderField('Di cosa si occupa', 'areas', 'text', 'La tua area professionale', 'Indica la tua area professionale')}
              </div>

              <div className="mt-6 space-y-6">
                {renderField('Curriculum', 'curriculum', 'textarea', 'ESEMPIO: Dopo la laurea in psicologia curriculum ..., ho frequentato un corso di perfezionamento ... e successivamente mi sono specializzata in psicoterapia con .... Ho approfondito .... Ho lavorato...lavoro presso...nel mio lavoro mi occupo di ....')}
                {renderField('Registrazione all\'Albo', 'registration', 'text', 'Numero di registrazione', 'specificare quale albo e la data di iscrizione')}
                {renderField('Laurea', 'degree', 'text', 'Il tuo titolo di studio', 'specificare il titolo di laurea e l\'Università')}
                {renderField('Specializzazione', 'specialization', 'text', 'La tua specializzazione', 'specificare il nome della specializzazione e la scuola presso la quale è stata conseguita')}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Tprofile;