import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PanelsTopLeft, FileText, User, Menu, X } from 'lucide-react';

// Hook per gestire i click esterni
const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
};

const Sidebar = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (isMobileOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isMobileOpen]);

  useClickOutside(sidebarRef, () => {
    if (isMobileOpen) setIsMobileOpen(false);
  });

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleLinkClick = () => {
    setIsMobileOpen(false);
  };

  const NavLink = ({ to, icon: Icon, children }) => {
    const active = isActive(to);
    
    return (
      <Link
        to={to}
        onClick={handleLinkClick}
        className={`
          flex items-center space-x-3 px-4 py-3 rounded-lg transition-all duration-200
          ${active ? 
            'bg-gradient-to-r from-emerald-50 to-teal-50 text-emerald-700 border-r-4 border-emerald-500' : 
            'text-gray-600 hover:bg-gradient-to-r hover:from-emerald-50/50 hover:to-teal-50/50 hover:text-emerald-600'
          }
        `}
      >
        <Icon className={`w-5 h-5 ${active ? 'text-emerald-600' : 'text-gray-500'}`} />
        <span className={`font-medium ${active ? 'text-emerald-700' : 'text-gray-600'}`}>
          {children}
        </span>
      </Link>
    );
  };

  return (
    <>
      {/* Mobile Toggle Button */}
      <div
        className={`
          fixed top-4 left-4 z-50 flex items-center space-x-4 p-2 rounded-lg bg-white shadow-lg md:hidden
          ${isMobileOpen ? 'hidden' : 'flex'}
        `}
      >
        <img
          src="/logo.png"
          alt="Logo"
          className="h-8 object-contain"
        />
        <button
          onClick={() => setIsMobileOpen(!isMobileOpen)}
          className="p-2 rounded-lg"
        >
          {isMobileOpen ? (
            <X className="w-6 h-6 text-emerald-600" />
          ) : (
            <Menu className="w-6 h-6 text-emerald-600" />
          )}
        </button>
      </div>

      {/* Overlay for mobile */}
      {isMobileOpen && (
        <div className="fixed inset-0 bg-black/20 z-40 md:hidden" />
      )}

      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`
          fixed inset-y-0 left-0 z-40 w-[280px] bg-white border-r border-gray-100 
          transform transition-transform duration-300 ease-in-out
          md:translate-x-0
          ${isMobileOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <div className="p-6 border-b border-gray-100">
          <img 
            src="/logo.png" 
            alt="Etherapy" 
            className="h-[100px] object-contain transition-all duration-200 hover:opacity-90" 
          />
        </div>
        
        <nav className="flex-1 p-4 space-y-2">
          <NavLink to="/dashboard-therapist" icon={PanelsTopLeft}>
            Dashboard
          </NavLink>
          
          <NavLink to="/therapistinvoicespage" icon={FileText}>
            Le tue fatture
          </NavLink>

          <NavLink to="/therapistprofile" icon={User}>
            Profilo personale
          </NavLink>
        </nav>

        <div className="p-4 border-t border-gray-100">
          <div className="p-4 rounded-lg bg-gradient-to-r from-emerald-50 to-teal-50">
            <h3 className="text-sm font-medium text-emerald-700 mb-2">
              Hai bisogno di aiuto?
            </h3>
            <p className="text-xs text-emerald-600">
              Contattaci via email o telefono per assistenza
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;