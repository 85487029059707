import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Importa Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyA4bRfnDJVznvSsJK62bDpdT1jqevj6i1o",
    authDomain: "etherapy-9ff15.firebaseapp.com",
    databaseURL: "https://etherapy-9ff15-default-rtdb.europe-west1.firebasedatabase.app/", // Questo è il tuo database URL
    projectId: "etherapy-9ff15",
    storageBucket: "etherapy-9ff15.firebasestorage.app", // Correggi il bucket (usare firebasestorage.app era errato)
    messagingSenderId: "552937838394",
    appId: "1:552937838394:web:83bc16bfc166241a4be203",
    measurementId: "G-JQJ4XQSCHF"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app); // Esporta Firebase Storage
export const googleProvider = new GoogleAuthProvider();
