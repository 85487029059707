import React, { useState, useEffect } from 'react';
import { ref, onValue, update, set, push } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { findPotentialPatients, validateTherapistProfile, validatePatientData } from '../../utils/matching';
import { MessageCircle, User, LogOut, ChevronDown, ChevronUp, NotebookPen, Trash2, Edit2, Plus, Calendar } from 'lucide-react';
import Sidebar from './Sidebar';
import ChatBox from './ChatBox';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import PatientDetailsModal from './PatientDetailsModal';
import { Calendar as CalendarComponent } from '../ui/calendar';
import { Button } from '../ui/button';
import { acceptMatch } from '../../services/matchService';

const TherapistDashboard = () => {
  const [therapistProfile, setTherapistProfile] = useState(null);
  const [pendingPatients, setPendingPatients] = useState([]);
  const [acceptedPatients, setAcceptedPatients] = useState([]);
  const [debugInfo, setDebugInfo] = useState({
    therapistValidation: null,
    totalPatients: 0,
    filteredPatients: 0,
    matchingErrors: []
  });
  const [photoURL, setPhotoURL] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
  const [openChats, setOpenChats] = useState({});
  const [openNotes, setOpenNotes] = useState({});
  const [notes, setNotes] = useState({});
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [newNoteText, setNewNoteText] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimeSlots, setAvailableTimeSlots] = useState({});
  const [bookedAppointments, setBookedAppointments] = useState([]);
  const [chatMessages, setChatMessages] = useState({});
  const [newChatMessage, setNewChatMessage] = useState({});
  const [showAvailabilitySection, setShowAvailabilitySection] = useState(false);

  // Time slots that can be selected
  const timeSlots = [
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
    "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
    "20:00", "20:30", "21:00", "21:30", "22:00"
  ];

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('No user ID found');
      return;
    }

    // Add listener for availability
    const availabilityRef = ref(database, `availability/${userId}`);
    const availabilityListener = onValue(availabilityRef, (snapshot) => {
      const data = snapshot.val() || {};
      setAvailableTimeSlots(data);
    });

    const userRef = ref(database, `users/${userId}`);

    // Listener per il profilo del terapeuta
    const therapistListener = onValue(userRef, (snapshot) => {
      const therapistData = snapshot.val();
      setPhotoURL(therapistData?.photoURL);

      // Validazione del profilo del terapeuta
      const therapistValidation = validateTherapistProfile(therapistData);
      if (!therapistValidation.isValid) {
        setDebugInfo(prev => ({
          ...prev,
          therapistValidation,
          matchingErrors: [...prev.matchingErrors, 'Profilo terapeuta non valido']
        }));
        return;
      }

      setTherapistProfile(therapistData);

      // Listener per i dati dei pazienti
      const patientsRef = ref(database, 'users');
      const patientsListener = onValue(patientsRef, (patientsSnapshot) => {
        const allUsers = patientsSnapshot.val();
        console.log('Dati dei pazienti:', allUsers);

        const patients = Object.entries(allUsers)
          .filter(([_, user]) => user.userType === 'patient')
          .map(([id, user]) => ({ ...user, id }));

        const invalidPatients = patients.filter(patient => {
          const validation = validatePatientData(patient);
          return !validation.isValid;
        });

        try {
          // Estrai l'array `matches` dall'oggetto restituito da `findPotentialPatients`
          let result = findPotentialPatients(patients, therapistData);
          let potentialMatches = Array.isArray(result.matches) ? result.matches : [];

          console.log('Pazienti potenzialmente abbinati:', potentialMatches);

          const pending = potentialMatches.filter(patient =>
            patient.matchStatus === 'pending' &&
            (!patient.matchedTherapistId || patient.matchedTherapistId === userId)
          );

          const accepted = potentialMatches.filter(patient =>
            patient.matchStatus === 'matched' && patient.matchedTherapistId === userId
          );

          setPendingPatients(pending);
          setAcceptedPatients(accepted);
          setDebugInfo({
            therapistValidation,
            totalPatients: patients.length,
            filteredPatients: potentialMatches.length,
            matchingErrors: invalidPatients.map(p => `Paziente non valido: ${p.id}`)
          });

        } catch (error) {
          setDebugInfo(prev => ({
            ...prev,
            matchingErrors: [...prev.matchingErrors, error.message]
          }));
        }

      });

      return () => {
        patientsListener();
      };
    });

    return () => {
      therapistListener();
      availabilityListener();
    };
  }, []);

  // Separate effect for appointments
  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    console.log('Setting up appointments listener for therapist:', userId);
    
    const appointmentsListener = onValue(ref(database, 'appointments'), (snapshot) => {
      const data = snapshot.val() || {};
      console.log('Raw appointments data:', data); // Debug log
      
      const allAppointments = Object.entries(data).flatMap(([patientId, patientAppointments]) => {
        if (!patientAppointments || typeof patientAppointments !== 'object') {
          return [];
        }
        return Object.entries(patientAppointments).map(([appointmentId, appointment]) => ({
          ...appointment,
          appointmentId,
          patientId
        }));
      });
      
      console.log('Processed appointments:', allAppointments); // Debug log
      
      const therapistAppointments = allAppointments
        .filter(appointment => {
          const isTherapistAppointment = appointment.therapistId === userId;
          console.log('Checking appointment:', appointment, 'isTherapist:', isTherapistAppointment); // Debug log
          return isTherapistAppointment;
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
      
      console.log('Filtered therapist appointments:', therapistAppointments); // Debug log
      setBookedAppointments(therapistAppointments);
    });

    return () => appointmentsListener();
  }, []); // Only run once on mount

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId || !acceptedPatients.length) return;

    const notesRef = ref(database, `therapistNotes/${userId}`);
    onValue(notesRef, (snapshot) => {
      if (snapshot.exists()) {
        const notesData = snapshot.val();
        // Converti ogni entry in array se non lo è già
        const formattedNotes = {};
        Object.keys(notesData).forEach(patientId => {
          formattedNotes[patientId] = Array.isArray(notesData[patientId]) 
            ? notesData[patientId] 
            : notesData[patientId] ? [notesData[patientId]] : [];
        });
        setNotes(formattedNotes);
      } else {
        setNotes({});
      }
    });
  }, [acceptedPatients]);

  // Add chat messages listener
  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId || !acceptedPatients.length) return;

    // Create listeners for each patient's chat
    const chatListeners = acceptedPatients.map(patient => {
      const chatRef = ref(database, `chats/${patient.id}`);
      return onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setChatMessages(prev => ({
            ...prev,
            [patient.id]: Object.values(data).sort((a, b) => a.timestamp - b.timestamp)
          }));
        }
      });
    });

    return () => {
      chatListeners.forEach(unsubscribe => unsubscribe());
    };
  }, [acceptedPatients]);

  const sendMessage = async (patientId) => {
    if (!newChatMessage[patientId]?.trim()) return;

    try {
      const chatRef = ref(database, `chats/${patientId}`);
      const newMessageRef = push(chatRef);

      const messageData = {
        text: newChatMessage[patientId],
        senderId: auth.currentUser.uid,
        senderType: 'therapist',
        timestamp: Date.now(),
      };

      await set(newMessageRef, messageData);
      setNewChatMessage(prev => ({ ...prev, [patientId]: '' }));
    } catch (error) {
      console.error('Errore nell\'invio del messaggio:', error);
    }
  };

  const handlePatientResponse = async (patientId, accept) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      if (accept) {
        // Use acceptMatch from matchService which will also send the email
        await acceptMatch(patientId, userId);
      } else {
        // Just update the status to rejected
        const patientRef = ref(database, `users/${patientId}`);
        await update(patientRef, {
          matchStatus: 'rejected',
          matchedTherapistId: null
        });
      }
    } catch (error) {
      console.error('Errore nell\'aggiornare lo stato del paziente:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Il reindirizzamento verrà gestito dal router/auth listener
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  const toggleChat = (patientId) => {
    setOpenChats(prev => ({
      ...prev,
      [patientId]: !prev[patientId]
    }));
  };

  const toggleNotes = (patientId) => {
    setOpenNotes(prev => ({
      ...prev,
      [patientId]: !prev[patientId]
    }));
  };

  const handleSaveNotes = async (patientId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: notes[patientId]
      });

      // Feedback visivo di successo (opzionale)
      alert('Note salvate con successo');
    } catch (error) {
      console.error('Errore nel salvare le note:', error);
      alert('Errore nel salvare le note');
    }
  };

  const handleSaveNote = async (patientId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || !newNoteText.trim()) return;

      const newNote = {
        id: Date.now().toString(),
        text: newNoteText,
        createdAt: new Date().toISOString()
      };

      const currentNotes = Array.isArray(notes[patientId]) ? notes[patientId] : [];
      const updatedNotes = [...currentNotes, newNote];

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: updatedNotes
      });

      setNewNoteText('');
    } catch (error) {
      console.error('Errore nel salvare la nota:', error);
      alert('Errore nel salvare la nota');
    }
  };

  const handleUpdateNote = async (patientId, noteId, updatedText) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const currentNotes = Array.isArray(notes[patientId]) ? notes[patientId] : [];
      const updatedNotes = currentNotes.map(note => 
        note.id === noteId ? { ...note, text: updatedText } : note
      );

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: updatedNotes
      });

      setEditingNoteId(null);
    } catch (error) {
      console.error('Errore nell\'aggiornare la nota:', error);
      alert('Errore nell\'aggiornare la nota');
    }
  };

  const handleDeleteNote = async (patientId, noteId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const currentNotes = Array.isArray(notes[patientId]) ? notes[patientId] : [];
      const updatedNotes = currentNotes.filter(note => note.id !== noteId);

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: updatedNotes
      });
    } catch (error) {
      console.error('Errore nell\'eliminare la nota:', error);
      alert('Errore nell\'eliminare la nota');
    }
  };

  const toggleTimeSlot = async (date, timeSlot) => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const dateStr = date.toISOString().split('T')[0];
    const currentSlots = availableTimeSlots[dateStr] || [];
    const updatedSlots = currentSlots.includes(timeSlot)
      ? currentSlots.filter(slot => slot !== timeSlot)
      : [...currentSlots, timeSlot].sort();

    try {
      await set(ref(database, `availability/${userId}/${dateStr}`), updatedSlots);
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      {/* Sidebar - Hidden on mobile, shown on desktop */}
      <div className="hidden md:block fixed inset-y-0 left-0 w-[280px] bg-white border-r border-gray-200 z-20">
        <Sidebar />
      </div>

      {/* Mobile Sidebar */}
      <div className="md:hidden">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="flex-1 w-full md:pl-[280px]">
        <div className="w-full p-4 md:p-8">
          {/* Header */}
          <header className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 bg-white rounded-xl p-4 md:p-6 shadow-sm w-full mt-16 md:mt-0">
            <div className="flex items-center gap-4 w-full md:w-auto">
              <div className="w-12 md:w-14 h-12 md:h-14 rounded-full overflow-hidden bg-gray-100 ring-2 ring-emerald-500/20">
                {photoURL ? (
                  <img 
                    src={photoURL} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <User className="w-6 md:w-7 h-6 md:h-7 text-gray-400" />
                  </div>
                )}
              </div>
              <div>
                <h1 className="text-xl md:text-2xl font-serif text-emerald-800 font-semibold">
                  Dashboard Terapeuta
                </h1>
                <p className="text-gray-500 text-sm">
                  {therapistProfile?.name} {therapistProfile?.surname}
                </p>
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="mt-4 md:mt-0 w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 text-gray-600 hover:text-red-600 transition-colors rounded-lg hover:bg-red-50"
            >
              <LogOut className="w-5 h-5" />
              <span>Logout</span>
            </button>
          </header>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6 w-full">
            {/* Availability Section */}
            <div className="space-y-4 md:space-y-6">
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                <CardHeader className="border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <CardTitle className="text-lg md:text-xl font-serif text-emerald-800 font-semibold flex items-center gap-2">
                      <Calendar className="w-5 h-5" />
                      Gestione Disponibilità
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="p-4 md:p-6">
                  <Button
                    onClick={() => setShowAvailabilitySection(!showAvailabilitySection)}
                    className="w-full bg-emerald-600 hover:bg-emerald-700 text-white flex items-center justify-center gap-2"
                  >
                    {showAvailabilitySection ? (
                      <>
                        <ChevronUp className="w-4 h-4" />
                        Chiudi disponibilità
                      </>
                    ) : (
                      <>
                        <Calendar className="w-4 h-4" />
                        Le tue disponibilità
                      </>
                    )}
                  </Button>

                  {showAvailabilitySection && (
                    <div className="space-y-4 mt-4">
                      <CalendarComponent
                        mode="single"
                        selected={selectedDate}
                        onSelect={setSelectedDate}
                        disabled={{ before: new Date() }}
                        className="rounded-lg border border-emerald-100"
                      />
                      
                      {selectedDate && (
                        <div className="mt-4">
                          <h3 className="text-sm font-medium text-gray-700 mb-3">
                            Seleziona gli orari disponibili per {selectedDate.toLocaleDateString('it-IT', { weekday: 'long', day: 'numeric', month: 'long' })}:
                          </h3>
                          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                            {timeSlots.map((slot) => {
                              const dateStr = selectedDate.toISOString().split('T')[0];
                              const isSelected = (availableTimeSlots[dateStr] || []).includes(slot);
                              
                              return (
                                <Button
                                  key={slot}
                                  variant="outline"
                                  onClick={() => toggleTimeSlot(selectedDate, slot)}
                                  className={`
                                    text-sm py-2 px-3
                                    ${isSelected 
                                      ? 'bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100'
                                      : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'}
                                  `}
                                >
                                  {slot}
                                </Button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>

            {/* Appointments Section */}
            <div className="space-y-4 md:space-y-6">
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                <CardHeader className="border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <CardTitle className="text-lg md:text-xl font-serif text-emerald-800 font-semibold flex items-center gap-2">
                      <Calendar className="w-5 h-5" />
                      Appuntamenti Prenotati
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="divide-y divide-gray-100">
                    {bookedAppointments.length > 0 ? (
                      bookedAppointments.map((appointment, index) => (
                        <div key={index} className="p-4 hover:bg-gray-50">
                          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
                            <div>
                              <p className="font-medium text-gray-900">
                                {appointment.name} {appointment.surname}
                              </p>
                              <p className="text-sm text-gray-500">
                                {new Date(appointment.date).toLocaleDateString('it-IT', {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric'
                                })}
                              </p>
                              <p className="text-sm text-emerald-600 font-medium">
                                {appointment.time}
                              </p>
                            </div>
                            <span className={`px-3 py-1 rounded-full text-sm w-fit ${
                              appointment.status === 'scheduled' 
                                ? 'bg-emerald-100 text-emerald-800'
                                : 'bg-gray-100 text-gray-800'
                            }`}>
                              {appointment.status}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="p-6 text-center text-gray-500">
                        Nessun appuntamento prenotato
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              {/* Pending Patients Section */}
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-4 md:p-6 border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg md:text-xl font-serif text-emerald-800 font-semibold">
                      Pazienti in Attesa
                    </h2>
                    <span className="bg-emerald-100 text-emerald-800 px-3 py-1 rounded-full text-sm">
                      {pendingPatients.length} in attesa
                    </span>
                  </div>
                </div>
                <div className="divide-y divide-gray-100">
                  {pendingPatients.map(patient => (
                    <div key={patient.id} className="p-4 md:p-6 hover:bg-gray-50 transition-colors">
                      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
                        <div>
                          <h3 className="font-medium text-gray-900">{patient.name} {patient.surname}</h3>
                          <p className="text-sm text-gray-500">{patient.email}</p>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row gap-3">
                        <button
                          onClick={() => handlePatientResponse(patient.id, true)}
                          className="flex-1 bg-emerald-500 text-white rounded-lg px-4 py-2 hover:bg-emerald-600 transition-colors"
                        >
                          Accetta
                        </button>
                        <button
                          onClick={() => handlePatientResponse(patient.id, false)}
                          className="flex-1 bg-white border border-gray-200 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-50 transition-colors"
                        >
                          Rifiuta
                        </button>
                      </div>
                    </div>
                  ))}
                  {pendingPatients.length === 0 && (
                    <div className="p-6 text-center text-gray-500">
                      Nessun paziente in attesa
                    </div>
                  )}
                </div>
              </div>

              {/* Accepted Patients Section */}
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-4 md:p-6 border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg md:text-xl font-serif text-emerald-800 font-semibold">
                      Pazienti Accettati
                    </h2>
                    <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                      {acceptedPatients.length} attivi
                    </span>
                  </div>
                </div>
                <div className="divide-y divide-gray-100">
                  {acceptedPatients.map(patient => (
                    <div key={patient.id} className="transition-all duration-200">
                      <div className="p-4 md:p-6 hover:bg-gray-50">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                          <div className="cursor-pointer hover:text-emerald-700 mb-4 sm:mb-0">
                            <h3 className="font-medium text-gray-900">{patient.name} {patient.surname}</h3>
                            <p className="text-sm text-gray-500">{patient.email}</p>
                          </div>
                          <div className="flex flex-wrap gap-2">
                            <button 
                              onClick={() => {
                                setSelectedPatient(patient);
                                setIsPatientModalOpen(true);
                              }}
                              className="inline-flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors text-gray-600 hover:bg-gray-100"
                            >
                              <User className="w-4 h-4" />
                              <span className="text-sm">Info</span>
                            </button>
                            
                            <button 
                              onClick={() => toggleNotes(patient.id)}
                              className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors ${
                                openNotes[patient.id] 
                                  ? 'bg-emerald-100 text-emerald-700' 
                                  : 'text-gray-600 hover:bg-gray-100'
                              }`}
                            >
                              <NotebookPen className="w-4 h-4" />
                              <span className="text-sm">Note</span>
                              {openNotes[patient.id] ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                            </button>
                            
                            <button 
                              onClick={() => toggleChat(patient.id)}
                              className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors ${
                                openChats[patient.id] 
                                  ? 'bg-emerald-100 text-emerald-700' 
                                  : 'text-gray-600 hover:bg-gray-100'
                              }`}
                            >
                              <MessageCircle className="w-4 h-4" />
                              <span className="text-sm">Chat</span>
                              {openChats[patient.id] ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                            </button>
                          </div>
                        </div>

                        {openNotes[patient.id] && (
                          <div className="mt-4 space-y-4 border-t pt-4">
                            <div className="space-y-3">
                              {(Array.isArray(notes[patient.id]) ? notes[patient.id] : []).map(note => (
                                <div key={note.id} className="bg-gray-50 rounded-lg p-4">
                                  {editingNoteId === note.id ? (
                                    <div className="space-y-2">
                                      <textarea
                                        value={note.text}
                                        onChange={(e) => {
                                          const updatedNotes = notes[patient.id].map(n =>
                                            n.id === note.id ? { ...n, text: e.target.value } : n
                                          );
                                          setNotes({ ...notes, [patient.id]: updatedNotes });
                                        }}
                                        className="w-full p-2 border rounded text-sm"
                                        rows="3"
                                      />
                                      <div className="flex justify-end gap-2">
                                        <button
                                          onClick={() => handleUpdateNote(patient.id, note.id, note.text)}
                                          className="bg-emerald-500 text-white rounded px-3 py-1 text-sm hover:bg-emerald-600"
                                        >
                                          Salva
                                        </button>
                                        <button
                                          onClick={() => setEditingNoteId(null)}
                                          className="bg-gray-200 text-gray-700 rounded px-3 py-1 text-sm hover:bg-gray-300"
                                        >
                                          Annulla
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="flex justify-between items-start mb-2">
                                        <p className="text-sm text-gray-700">{note.text}</p>
                                        <div className="flex gap-2 ml-4">
                                          <button
                                            onClick={() => setEditingNoteId(note.id)}
                                            className="text-gray-500 hover:text-emerald-600"
                                          >
                                            <Edit2 className="w-4 h-4" />
                                          </button>
                                          <button
                                            onClick={() => handleDeleteNote(patient.id, note.id)}
                                            className="text-gray-500 hover:text-red-600"
                                          >
                                            <Trash2 className="w-4 h-4" />
                                          </button>
                                        </div>
                                      </div>
                                      <p className="text-xs text-gray-400">
                                        {new Date(note.createdAt).toLocaleDateString('it-IT', {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                        })}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>

                            <div className="space-y-2">
                              <textarea
                                value={newNoteText}
                                onChange={(e) => setNewNoteText(e.target.value)}
                                placeholder="Scrivi una nuova nota..."
                                className="w-full p-3 border rounded-lg text-sm text-gray-700 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                rows="3"
                              />
                              <div className="flex justify-end">
                                <button
                                  onClick={() => handleSaveNote(patient.id)}
                                  disabled={!newNoteText.trim()}
                                  className="inline-flex items-center gap-2 bg-emerald-500 text-white rounded-lg px-4 py-2 text-sm hover:bg-emerald-600 transition-colors disabled:opacity-50"
                                >
                                  <Plus className="w-4 h-4" />
                                  Aggiungi nota
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {openChats[patient.id] && (
                          <div className="mt-4 space-y-4 border-t pt-4">
                            <div className="min-h-[200px] max-h-[300px] overflow-y-auto p-4 bg-gray-50 rounded-lg">
                              {chatMessages[patient.id]?.length > 0 ? (
                                <div className="space-y-3">
                                  {chatMessages[patient.id].map((message, index) => (
                                    <div
                                      key={index}
                                      className={`flex ${message.senderType === 'therapist' ? 'justify-end' : 'justify-start'}`}
                                    >
                                      <div
                                        className={`max-w-[70%] px-3 py-2 rounded-lg ${
                                          message.senderType === 'therapist'
                                            ? 'bg-emerald-600 text-white'
                                            : 'bg-white border border-gray-200'
                                        }`}
                                      >
                                        <p className="text-sm">{message.text}</p>
                                        <span className="text-xs mt-1 block opacity-70">
                                          {new Date(message.timestamp).toLocaleTimeString('it-IT', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                          })}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="text-sm text-gray-500 text-center">
                                  Inizio della conversazione
                                </div>
                              )}
                            </div>
                            <div className="flex gap-2">
                              <input
                                type="text"
                                value={newChatMessage[patient.id] || ''}
                                onChange={(e) => setNewChatMessage(prev => ({
                                  ...prev,
                                  [patient.id]: e.target.value
                                }))}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    sendMessage(patient.id);
                                  }
                                }}
                                placeholder="Scrivi un messaggio..."
                                className="flex-1 p-2 border rounded-lg text-sm text-gray-700 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                              />
                              <button 
                                onClick={() => sendMessage(patient.id)}
                                className="bg-emerald-500 text-white rounded-lg px-4 py-2 text-sm hover:bg-emerald-600 transition-colors"
                              >
                                Invia
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {acceptedPatients.length === 0 && (
                    <div className="p-6 text-center text-gray-500">
                      Nessun paziente accettato
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PatientDetailsModal 
        patient={selectedPatient}
        open={isPatientModalOpen}
        onOpenChange={setIsPatientModalOpen}
      />
    </div>
  );
};

export default TherapistDashboard;