// src/App.js
import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import DashboardPatient from "./pages/DashboardPatient";
import DashboardTherapist from "./pages/DashboardTherapist";
import PatientOnboarding from "./pages/PatientOnboarding";

///////
import PatientLogin from "./pages/PatientLogin";
import PatientRegister from "./pages/PatienRegister";
import TherapistRegister from "./pages/TherapistRegister";
import TherapistLogin from "./pages/TherapistLogin";


//////////
import PatientForm from "./pages/PatientForm";
import TherapistForm from "./pages/TherapistForm";


////////
import ProfilePage from "./components/Patient/ProfilePage";

////////
import Sedute from "./components/Patient/InvoicePage";


//////////
import TherapistInvoicesPage from "./components/Therapist/TherapistInvoicesPage";

//////////
import { getDatabase, ref, onValue } from 'firebase/database';
import { app, auth } from './firebaseConfig';


//////////
import Tprofile from './components/Therapist/Tprofile';



const AuthContext = createContext();


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
    } catch {
      return false;
    }
  });
  const [userType, setUserType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const db = getDatabase(app);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);

        const userRef = ref(db, `users/${user.uid}`);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setUserType(data.userType);
          }
          setIsLoading(false);
        });
      } else {
        setIsLoggedIn(false);
        setUserType(null);
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [db]);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const ProtectedRoute = ({
    component: Component,
    requiredUserType,
    ...rest
  }) => {
    const { isLoggedIn, userType, isLoading } = useContext(AuthContext);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!isLoggedIn || (requiredUserType && userType !== requiredUserType)) {
      return <Navigate to="/" />;
    }

    return <Component {...rest} />;
  };


  return (
    <Router>
      <AuthContext.Provider value={{ isLoggedIn, userType, isLoading }}>

        <div className="flex">

          <div className="flex-1">
            <main className="">
              <Routes>
                <Route
                  path="/dashboard-patient"
                  element={
                    <ProtectedRoute
                      component={DashboardPatient}
                      requiredUserType="patient"
                    />
                  }
                />
                <Route path="/" element={<PatientLogin />} />
                <Route path="/PatientRegister" element={<PatientRegister />} />

                <Route path="/TherapistRegister" element={<TherapistRegister />} />
                <Route path="/TherapistLogin" element={<TherapistLogin />} />


                <Route
                  path="/patient-form"
                  element={
                    <ProtectedRoute
                      component={PatientForm}
                      requiredUserType="patient"
                    />
                  }
                />
                <Route
                  path="/therapist-form"
                  element={
                    <ProtectedRoute
                      component={TherapistForm}
                      requiredUserType="therapist"
                    />
                  }
                />
                <Route
                  path="/profilepage"
                  element={
                    <ProtectedRoute
                      component={ProfilePage}
                      requiredUserType="patient"
                    />
                  }
                />
                <Route
                  path="/Sedute"
                  element={
                    <ProtectedRoute
                      component={Sedute}
                      requiredUserType="patient"
                    />
                  }
                />

                <Route
                  path="/TherapistInvoicesPage"
                  element={
                    <ProtectedRoute
                      component={TherapistInvoicesPage}
                      requiredUserType="therapist"
                    />
                  }
                />

                <Route
                  path="/TherapistInvoicesPage"
                  element={
                    <ProtectedRoute
                      component={TherapistInvoicesPage}
                      requiredUserType="therapist"
                    />
                  }
                />
                <Route
                  path="/dashboard-therapist"
                  element={
                    <ProtectedRoute
                      component={DashboardTherapist}
                      requiredUserType="therapist"
                    />
                  }
                />
                 <Route
                  path="/therapistprofile"
                  element={
                    <ProtectedRoute
                      component={Tprofile}
                      requiredUserType="therapist"
                    />
                  }
                />
                <Route path="/patient-onboarding" element={<PatientOnboarding />} />
                {/* <Route path="/dashboard-therapist" element={<DashboardTherapist />} /> */}

                <Route path="*" element={<div>Pagina non trovata</div>} />
              </Routes>
            </main>
          </div>
        </div>
      </AuthContext.Provider>

    </Router>
  );
}

export default App;
