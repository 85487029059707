// src/pages/PatientLogin.jsx
import React, { useState } from "react";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { ref, get, set } from "firebase/database";
import { database } from "../firebaseConfig";

const PatientLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userRef = ref(database, `users/${userCredential.user.uid}`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        if (!userData.profileComplete) {
          navigate("/patient-form");
        } else {
          navigate("/dashboard-patient");
        }
      } else {
        navigate("/patient-form");
      }
    } catch (err) {
      setError("Credenziali non valide. Riprova.");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if user exists in database
      const userRef = ref(database, `users/${user.uid}`);
      const snapshot = await get(userRef);
      
      if (!snapshot.exists()) {
        // Create new user in database
        await set(userRef, {
          name: user.displayName,
          email: user.email,
          userType: 'patient',
          userId: user.uid,
          profileComplete: false,
          createdAt: new Date().toISOString()
        });
        // Redirect to form for new users
        navigate("/patient-form");
      } else {
        // Check if existing user has completed the form
        const userData = snapshot.val();
        if (!userData.profileComplete) {
          navigate("/patient-form");
        } else {
          navigate("/dashboard-patient");
        }
      }
    } catch (err) {
      setError("Errore durante l'accesso con Google. Riprova.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleLogin} className="flex flex-col max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <div className="mb-5 flex justify-center">
          <img src="/logo.png" alt="Etherapy" className="h-[100px]" />
        </div>
        <h2 className="text-2xl font-bold mb-7 text-center text-emerald-700">Accesso Paziente</h2>
        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="mb-2 p-2 border rounded"
        />
        <button type="submit" className="text-white py-2 px-4 rounded bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-all">
          Accedi come Paziente
        </button>
        
        <div className="my-4 flex items-center">
          <div className="flex-1 border-t border-gray-300"></div>
          <span className="px-4 text-gray-500">oppure</span>
          <div className="flex-1 border-t border-gray-300"></div>
        </div>
        
        <button
          type="button"
          onClick={handleGoogleLogin}
          className="flex items-center justify-center gap-2 py-2 px-4 border border-gray-300 rounded-lg hover:bg-gray-50 transition-all"
        >
          <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5" />
          Accedi con Google
        </button>
        
        <p className="text-sm text-center mt-4">
          Non hai ancora un account? <a href="/patientregister" className="text-blue-900">Registrati ora!</a>
        </p>
      </form>
    </div>
  );
};

export default PatientLogin;
