import { getDatabase, ref, get } from 'firebase/database';
import { app } from '../firebaseConfig';
import emailjs from '@emailjs/browser';

// Initialize EmailJS with your user ID
emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);

// Send registration confirmation email
export const sendRegistrationEmail = async (email, userType) => {
  try {
    const templateParams = {
      to_email: email,
      user_type: userType,
      message: `Benvenuto su eTherapy${userType === 'therapist' 
        ? ', la piattaforma per offrire terapia online.' 
        : ', la piattaforma per la terapia online.'}`
    };

    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_MATCH_CONFIRMATION_TEMPLATE_ID, // Using the confirmation template for registration
      templateParams
    );

    console.log('Registration email sent successfully');
  } catch (error) {
    console.error('Error sending registration email:', error);
    // Don't throw the error for registration emails to prevent blocking the registration flow
  }
};

// Send match request email to therapist
export const sendMatchRequestEmail = async (therapistId, patientId) => {
  try {
    console.log('Starting sendMatchRequestEmail with:', { therapistId, patientId });
    
    const db = getDatabase(app);
    const therapistRef = ref(db, `users/${therapistId}`);
    const patientRef = ref(db, `users/${patientId}`);

    console.log('Fetching user data from Firebase...');
    const [therapistSnapshot, patientSnapshot] = await Promise.all([
      get(therapistRef),
      get(patientRef)
    ]);

    if (!therapistSnapshot.exists()) {
      throw new Error(`Therapist with ID ${therapistId} not found`);
    }
    if (!patientSnapshot.exists()) {
      throw new Error(`Patient with ID ${patientId} not found`);
    }

    const therapistEmail = therapistSnapshot.val().email;
    const patientName = patientSnapshot.val().name;
    const therapistName = therapistSnapshot.val().name;

    console.log('Preparing email template params:', {
      therapistEmail,
      patientName,
      therapistName
    });

    const templateParams = {
      to_email: therapistEmail,
      patient_name: patientName,
      therapist_name: therapistName,
      message: `Hai una nuova richiesta di abbinamento dal paziente ${patientName}. Accedi alla tua dashboard per accettare o rifiutare la richiesta.`
    };

    console.log('Sending email with EmailJS...', {
      serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      templateId: process.env.REACT_APP_EMAILJS_MATCH_REQUEST_TEMPLATE_ID
    });

    const response = await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_MATCH_REQUEST_TEMPLATE_ID,
      templateParams
    );

    console.log('Match request email sent successfully:', response);
  } catch (error) {
    console.error('Error sending match request email:', error);
    console.error('Error details:', {
      message: error.message,
      stack: error.stack,
      emailjsError: error.text
    });
    throw error;
  }
};

// Send match confirmation email to patient
export const sendMatchConfirmationEmail = async (patientId, therapistId) => {
  try {
    console.log('Starting sendMatchConfirmationEmail with:', { patientId, therapistId });
    
    const db = getDatabase(app);
    const therapistRef = ref(db, `users/${therapistId}`);
    const patientRef = ref(db, `users/${patientId}`);

    console.log('Fetching user data from Firebase...');
    const [therapistSnapshot, patientSnapshot] = await Promise.all([
      get(therapistRef),
      get(patientRef)
    ]);

    if (!therapistSnapshot.exists()) {
      throw new Error(`Therapist with ID ${therapistId} not found`);
    }
    if (!patientSnapshot.exists()) {
      throw new Error(`Patient with ID ${patientId} not found`);
    }

    const patientEmail = patientSnapshot.val().email;
    const therapistName = therapistSnapshot.val().name;
    const patientName = patientSnapshot.val().name;

    console.log('Preparing email template params:', {
      patientEmail,
      therapistName,
      patientName
    });

    const templateParams = {
      to_email: patientEmail,
      therapist_name: therapistName,
      patient_name: patientName,
      message: `Ottime notizie! ${therapistName} ha accettato la tua richiesta di terapia. Ora puoi programmare la tua prima sessione attraverso la piattaforma.`
    };

    console.log('Sending email with EmailJS...', {
      serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      templateId: process.env.REACT_APP_EMAILJS_MATCH_CONFIRMATION_TEMPLATE_ID
    });

    const response = await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_MATCH_CONFIRMATION_TEMPLATE_ID,
      templateParams
    );

    console.log('Match confirmation email sent successfully:', response);
  } catch (error) {
    console.error('Error sending match confirmation email:', error);
    console.error('Error details:', {
      message: error.message,
      stack: error.stack,
      emailjsError: error.text
    });
    throw error;
  }
}; 