import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { FileText, CreditCard, User } from 'lucide-react';
import Sidebar from './Sidebar';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';

const TherapistInvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);
  const [photoURL, setPhotoURL] = useState(null);
  const [therapistProfile, setTherapistProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Get therapist profile
        const userRef = ref(database, `users/${user.uid}`);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          setPhotoURL(data?.photoURL);
          setTherapistProfile(data);
        });

        // Get invoices
        const invoicesRef = ref(database, 'invoices');
        onValue(invoicesRef, (snapshot) => {
          const data = snapshot.val();
          const userInvoices = [];

          if (data) {
            Object.keys(data).forEach((patientId) => {
              const patientInvoices = data[patientId];
              Object.keys(patientInvoices).forEach((invoiceId) => {
                const invoice = patientInvoices[invoiceId];
                if (invoice.therapistId === user.uid) {
                  userInvoices.push({ ...invoice, id: invoiceId, patientId });
                }
              });
            });
          }

          // Sort invoices by date (most recent first)
          userInvoices.sort((a, b) => new Date(b.date) - new Date(a.date));
          setInvoices(userInvoices);
        });
      } else {
        setInvoices([]);
      }
    });

    return unsubscribe;
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "Data non disponibile";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Data non valida";
    return new Intl.DateTimeFormat('it-IT', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date);
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  const payInvoice = async (invoiceId, patientId) => {
    try {
      const invoiceRef = ref(database, `invoices/${patientId}/${invoiceId}`);
      await update(invoiceRef, { status: 'paid' });
      alert("Fattura pagata con successo!");
    } catch (error) {
      console.error("Errore durante il pagamento della fattura:", error);
      alert("Si è verificato un errore durante il pagamento della fattura.");
    }
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      {/* Sidebar - Hidden on mobile, shown on desktop */}
      <div className="hidden md:block fixed inset-y-0 left-0 w-[280px] bg-white border-r border-gray-200 z-20">
        <Sidebar />
      </div>

      {/* Mobile Sidebar */}
      <div className="md:hidden">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="flex-1 w-full md:pl-[280px]">
        <div className="w-full p-4 md:p-8">
          {/* Header */}
          <header className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 bg-white rounded-xl p-4 md:p-6 shadow-sm w-full mt-16 md:mt-0">
            <div className="flex items-center gap-4 w-full md:w-auto">
              <div className="w-12 md:w-14 h-12 md:h-14 rounded-full overflow-hidden bg-gray-100 ring-2 ring-emerald-500/20">
                {photoURL ? (
                  <img 
                    src={photoURL} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <User className="w-6 md:w-7 h-6 md:h-7 text-gray-400" />
                  </div>
                )}
              </div>
              <div>
                <h1 className="text-xl md:text-2xl font-serif text-emerald-800 font-semibold">
                  Le Tue Fatture
                </h1>
                <p className="text-gray-500 text-sm">
                  {therapistProfile?.name} {therapistProfile?.surname}
                </p>
              </div>
            </div>
          </header>

          <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
            <CardHeader className="border-b border-gray-100">
              <CardTitle className="text-lg md:text-xl font-serif text-emerald-800 font-semibold flex items-center gap-2">
                <FileText className="w-5 h-5" />
                Elenco Fatture
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              {/* Mobile View */}
              <div className="block md:hidden">
                <div className="divide-y divide-gray-100">
                  {invoices.length > 0 ? (
                    invoices.map((invoice, index) => (
                      <div key={index} className="p-4 hover:bg-gray-50">
                        <div className="flex flex-col gap-2">
                          <div className="flex justify-between items-start">
                            <div>
                              <p className="font-medium text-gray-900">Fattura #{invoice.id}</p>
                              <p className="text-sm text-gray-500">{formatDate(invoice.date)}</p>
                            </div>
                            <span
                              className={`px-2 py-1 rounded text-sm ${
                                invoice.status === 'paid'
                                  ? 'bg-emerald-100 text-emerald-600'
                                  : 'bg-yellow-100 text-yellow-600'
                              }`}
                            >
                              {invoice.status === 'paid' ? 'Pagata' : 'In attesa'}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-lg font-semibold text-gray-900">
                              {formatAmount(invoice.amount)}
                            </p>
                            {invoice.status === 'unpaid' && (
                              <button
                                onClick={() => payInvoice(invoice.id, invoice.patientId)}
                                className="inline-flex items-center gap-2 px-3 py-1.5 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
                              >
                                <CreditCard className="w-4 h-4" />
                                <span className="text-sm">Paga ora</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="p-8 text-center">
                      <FileText className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                      <p className="text-gray-500">Nessuna fattura disponibile al momento</p>
                    </div>
                  )}
                </div>
              </div>

              {/* Desktop View */}
              <div className="hidden md:block overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Numero</th>
                      <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Data</th>
                      <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Importo</th>
                      <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Stato</th>
                      <th className="px-6 py-4 text-right text-sm font-medium text-gray-500">Azioni</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {invoices.length > 0 ? (
                      invoices.map((invoice, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 text-sm text-gray-900">#{invoice.id}</td>
                          <td className="px-6 py-4 text-sm text-gray-600">{formatDate(invoice.date)}</td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-900">{formatAmount(invoice.amount)}</td>
                          <td className="px-6 py-4">
                            <span
                              className={`px-2 py-1 rounded text-sm ${
                                invoice.status === 'paid'
                                  ? 'bg-emerald-100 text-emerald-600'
                                  : 'bg-yellow-100 text-yellow-600'
                              }`}
                            >
                              {invoice.status === 'paid' ? 'Pagata' : 'In attesa'}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-right">
                            {invoice.status === 'unpaid' && (
                              <button
                                onClick={() => payInvoice(invoice.id, invoice.patientId)}
                                className="inline-flex items-center gap-2 px-3 py-1.5 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
                              >
                                <CreditCard className="w-4 h-4" />
                                <span className="text-sm">Paga ora</span>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="px-6 py-8 text-center">
                          <FileText className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                          <p className="text-gray-500">Nessuna fattura disponibile al momento</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TherapistInvoicesPage;