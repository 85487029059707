// src/services/matchService.js
import { ref, get, set, update } from "firebase/database";
import { database } from "../firebaseConfig";
import { findPotentialPatients } from "../utils/matching";
import { sendMatchRequestEmail, sendMatchConfirmationEmail } from "./emailService";

export const createMatch = async (patientId, therapistId) => {
  const matchRef = ref(database, `matches/${patientId}_${therapistId}`);
  const matchData = {
    patientId,
    therapistId,
    status: "pending",
    timestamp: Date.now(),
  };
  await set(matchRef, matchData);
  
  // Send match request email to therapist
  await sendMatchRequestEmail(therapistId, patientId);
};

export const acceptMatch = async (patientId, therapistId) => {
  const matchRef = ref(database, `matches/${patientId}_${therapistId}`);
  await update(matchRef, {
    status: "accepted",
    acceptedAt: Date.now()
  });

  // Update patient's match status
  const patientRef = ref(database, `users/${patientId}`);
  await update(patientRef, {
    matchStatus: "matched",
    matchedTherapistId: therapistId
  });

  // Update therapist's matches
  const therapistRef = ref(database, `users/${therapistId}`);
  const therapistSnapshot = await get(therapistRef);
  const currentMatches = therapistSnapshot.val().matches || [];
  await update(therapistRef, {
    matches: [...currentMatches, patientId]
  });

  // Send match confirmation email to patient
  await sendMatchConfirmationEmail(patientId, therapistId);
};

export const findBestTherapist = async (patientData) => {
  const therapistRef = ref(database, "therapists");
  const snapshot = await get(therapistRef);
  
  if (snapshot.exists()) {
    const therapists = Object.values(snapshot.val());
    const result = findPotentialPatients(therapists, patientData);
    const matches = result.matches;
    if (matches.length > 0) {
      return matches[0];
    }
  }
  return null;
};

export const handleAutoMatch = async (patientId, patientData) => {
  const bestTherapist = await findBestTherapist(patientData);
  if (bestTherapist) {
    await createMatch(patientId, bestTherapist.id);
    return bestTherapist;
  }
  return null;
};
