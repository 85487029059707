import React from "react";
import { useNavigate } from "react-router-dom";

const PatientRegister = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    // Redirect to the new onboarding flow
    navigate('/patient-onboarding');
  }, [navigate]);

  return null; // Component will redirect immediately
};

export default PatientRegister;