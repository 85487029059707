import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, push, set, update } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { MessageCircle, User, Send, LockKeyhole, LogOut, Search, ChevronUp, Calendar } from 'lucide-react';
import Sidebar from './Sidebar';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Calendar as CalendarComponent } from '../ui/calendar';
import ChatbotComponent from './ChatbotComponent';
import TherapistDetailsModal from './TherapistDetailsModal';
import { AlertDialogTrigger } from "../../components/ui/alert-dialog";
import CancelMatchDialog from "./CancelMatchDialog";


// Component definitions remain the same
const ChatMessage = ({ message, isOwnMessage, isTherapistMessage }) => (
  <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
    <div
      className={`max-w-[85%] sm:max-w-[70%] px-3 py-2 rounded-lg backdrop-blur-sm  ${isOwnMessage
          ? 'bg-white/90 text-right text-gray-800 z-10'
          : isTherapistMessage
            ? 'bg-gradient-to-r from-emerald-600 to-teal-600/90 text-white z-10'
            : 'bg-white/90 text-gray-800'
        }`}
    >
      <p className="text-sm">{message.text}</p>
      <span className="text-xs mt-1 block opacity-70">
        {new Date(message.timestamp).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
      </span>
    </div>
  </div>
);

const TimeSlotPicker = ({ availableSlots, onSelectTime }) => {
  if (!availableSlots || availableSlots.length === 0) {
    return (
      <div className="text-center p-4 text-gray-500">
        Nessun orario disponibile per questa data. Per favore seleziona un'altra data.
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-2 mt-4">
      {availableSlots.map((slot) => (
        <Button
          key={slot}
          variant="outline"
          onClick={() => onSelectTime(slot)}
          className="text-sm py-2 bg-white/80 hover:bg-emerald-50 border-emerald-200 text-emerald-700"
        >
          {slot}
        </Button>
      ))}
    </div>
  );
};

const PatientDashboard = () => {
  const [profile, setProfile] = useState(null);
  const [therapist, setTherapist] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [bookingError, setBookingError] = useState(null);
  const [isFirstInvoicePaid, setIsFirstInvoicePaid] = useState();
  const chatEndRef = useRef(null);
  const [isFirstAppointment, setIsFirstAppointment] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [therapistAvailability, setTherapistAvailability] = useState({});
  const [showBookingSection, setShowBookingSection] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [availableDates, setAvailableDates] = useState(new Set());

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('No user ID found');
      return;
    }

    const userRef = ref(database, `users/${userId}`);
    const userListener = onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setProfile({ id: userId, ...userData });

        if (userData.matchStatus === 'matched' && userData.matchedTherapistId) {
          const therapistRef = ref(database, `users/${userData.matchedTherapistId}`);
          onValue(therapistRef, (therapistSnapshot) => {
            const therapistData = therapistSnapshot.val();
            if (therapistData) {
              setTherapist({ id: userData.matchedTherapistId, ...therapistData });
            }
          });
        }
      }
    });

    const appointmentsRef = ref(database, `appointments/${userId}`);
    const appointmentsListener = onValue(appointmentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const appointments = Object.values(data);
        setAppointments(appointments);
        setIsFirstAppointment(appointments.length === 0);
      }
    });

    return () => {
      userListener();
      appointmentsListener();
    };
  }, []);

  // Single useEffect for therapist availability
  useEffect(() => {
    if (!therapist?.id) return;

    const availabilityRef = ref(database, `availability/${therapist.id}`);
    const availabilityListener = onValue(availabilityRef, (snapshot) => {
      const data = snapshot.val() || {};
      setTherapistAvailability(data);
      
      // Pre-calculate available dates starting from today
      const availableDatesSet = new Set();
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      Object.entries(data).forEach(([dateStr, slots]) => {
        if (slots && slots.length > 0) {
          const dateToCheck = new Date(dateStr);
          // Filter out slots that are in the past for today
          if (dateStr === today.toISOString().split('T')[0]) {
            const futureSlots = slots.filter(slot => {
              const [hours, minutes] = slot.split(':');
              const slotTime = new Date();
              slotTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
              return slotTime > now;
            });
            if (futureSlots.length > 0) {
              availableDatesSet.add(dateStr);
              // Update the available slots for today
              data[dateStr] = futureSlots;
            }
          } else if (dateToCheck > today) {
            availableDatesSet.add(dateStr);
          }
        }
      });
      setTherapistAvailability(data);
      setAvailableDates(availableDatesSet);
    });

    return () => availabilityListener();
  }, [therapist?.id]);

  useEffect(() => {
    if (profile?.matchStatus === 'matched' && therapist) {
      const messagesRef = ref(database, `chats/${profile.id}`);
      const messagesListener = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setMessages(Object.values(data).sort((a, b) => a.timestamp - b.timestamp));
        }
      });

      return () => messagesListener();
    }
  }, [profile?.matchStatus, therapist, profile?.id]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !therapist) return;

    const chatId = `${profile.id}`;
    const messagesRef = ref(database, `chats/${chatId}`);
    const newMessageRef = push(messagesRef);

    const messageData = {
      text: newMessage,
      senderId: profile.id,
      senderType: 'patient',
      timestamp: Date.now(),
    };

    await set(newMessageRef, messageData);
    setNewMessage('');
  };

  const getAvailableSlotsForDate = (date) => {
    if (!date) return [];
    const dateStr = date.toISOString().split('T')[0];
    const slots = therapistAvailability[dateStr] || [];
    
    // If it's today, only return future slots
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const selectedDate = new Date(date);
    
    if (selectedDate.getTime() === today.getTime()) {
      return slots.filter(slot => {
        const [hours, minutes] = slot.split(':');
        const slotTime = new Date();
        slotTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        return slotTime > now;
      });
    }
    
    return slots;
  };

  // Memoized function to check if a date has available slots
  const hasAvailableSlots = React.useCallback((date) => {
    const dateStr = date.toISOString().split('T')[0];
    return availableDates.has(dateStr);
  }, [availableDates]);

  // Memoized calendar disable function
  const isDateDisabled = React.useCallback((date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    // Convert input date to midnight for comparison
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);

    // Disable dates before today
    if (compareDate < today) return true;
    
    // Disable dates more than 30 days in the future
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 30);
    if (compareDate > maxDate) return true;

    // Only enable dates that have available slots
    return !hasAvailableSlots(date);
  }, [hasAvailableSlots]);

  const bookAppointment = async (timeSlot) => {
    if (!selectedDate) return setBookingError("Seleziona una data");
    if (!timeSlot) return setBookingError("Seleziona un orario");
    if (isBooking) return; // Prevent double booking

    try {
      setIsBooking(true);
      setBookingError(null);

      const appointmentData = {
        date: selectedDate.toISOString(),
        time: timeSlot,
        therapistId: therapist.id,
        therapistName: therapist.name,
        therapistSurname: therapist.surname,
        patientId: profile.id,
        name: profile.name,
        surname: profile.surname,
        status: 'scheduled',
        createdAt: new Date().toISOString()
      };

      const appointmentRef = ref(database, `appointments/${profile.id}`);
      const newAppointmentRef = push(appointmentRef);
      await set(newAppointmentRef, appointmentData);

      // Save invoice if it's not the first appointment
      if (!isFirstAppointment) {
        const invoiceData = {
          date: selectedDate.toISOString(),
          amount: 59,
          status: 'unpaid',
          therapistId: therapist.id,
          name: profile.name,
          surname: profile.surname,
          therapistName: therapist.name,
          therapistSurname: therapist.surname,
        };
        const invoiceRef = ref(database, `invoices/${profile.id}`);
        await set(push(invoiceRef), invoiceData);
      }

      // Remove the booked slot from therapist's availability
      const dateStr = selectedDate.toISOString().split('T')[0];
      const updatedSlots = (therapistAvailability[dateStr] || []).filter(slot => slot !== timeSlot);
      await set(ref(database, `availability/${therapist.id}/${dateStr}`), updatedSlots);

      setSelectedDate(null);
      setIsFirstAppointment(false);
      setShowBookingSection(false);
      alert("Appuntamento prenotato con successo!");
    } catch (error) {
      console.error('Error booking appointment:', error);
      setBookingError("Errore nella prenotazione");
    } finally {
      setIsBooking(false);
    }
  };

  const renderChat = () => {
    return (
      <>
        <div className="h-[300px] sm:h-[400px] overflow-y-auto p-3 sm:p-4 space-y-3 sm:space-y-4 z-10">
          {messages.map((msg, i) => (
            <ChatMessage
              key={i}
              message={msg}
              isOwnMessage={msg.senderId === profile?.id}
              isTherapistMessage={msg.senderType === 'therapist'}
            />
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="p-3 sm:p-4 border-t">
          <form onSubmit={sendMessage} className="flex gap-2">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Scrivi un messaggio..."
              className="flex-1 px-3 py-2 text-sm sm:text-base rounded-lg bg-white border-0 focus:ring-2 focus:ring-emerald-500 placeholder-gray-500"
            />
            <Button type="submit" className="bg-emerald-600 hover:bg-emerald-700">
              <Send className="w-4 h-4 sm:w-5 sm:h-5" />
            </Button>
          </form>
        </div>
      </>
    );
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Il reindirizzamento verrà gestito dal router/auth listener
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  const handleCancelMatch = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      // Aggiorna lo stato dell'utente
      await update(ref(database, `users/${userId}`), {
        matchStatus: 'pending',
        matchedTherapistId: null,
        lastUpdated: new Date().toISOString()
      });

      // Aggiorna lo stato del terapeuta
      if (profile?.matchedTherapistId) {
        const therapistRef = ref(database, `users/${profile.matchedTherapistId}/patients/${userId}`);
        await update(therapistRef, {
          status: 'cancelled',
          cancelledAt: new Date().toISOString()
        });
      }

      setShowCancelDialog(false);
      // La pagina si aggiornerà automaticamente grazie al listener sul profilo
    } catch (error) {
      console.error('Errore durante la cancellazione del match:', error);
      alert('Si è verificato un errore. Riprova più tardi.');
    }
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      <div className="fixed top-0 left-0 right-0 z-20 bg-white shadow-sm lg:hidden">
        <Sidebar />
      </div>

      <div className="hidden lg:block w-[280px] fixed">
        <Sidebar />
      </div>

      <div className="flex-1 lg:ml-[280px] p-4 sm:p-6 lg:p-8 mt-16 lg:mt-0">
        <div className="max-w-5xl mx-auto">
          <header className="flex justify-between items-center mb-6">
            <h1 className="text-2xl sm:text-3xl font-serif text-emerald-800 font-semibold">
              Il Tuo Percorso
            </h1>
            <div className="flex items-center gap-4">
              <button
                onClick={handleLogout}
                className="flex items-center gap-2 px-4 py-2 text-red-600 hover:text-red-700 transition-colors"
              >
                <LogOut className="w-5 h-5" />
                <span className="hidden sm:inline">Logout</span>
              </button>
            </div>
          </header>

          {profile?.matchStatus === 'matched' ? (
            <>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 mb-4 sm:mb-6">
                <Card className="border-0 bg-white/90">
                  <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                    <div className="flex justify-between items-center">
                      <CardTitle className="text-base sm:text-lg font-medium flex items-center gap-2">
                        Il tuo terapeuta
                      </CardTitle>
                      <button
                        onClick={() => setShowCancelDialog(true)}
                        className="text-white/80 hover:text-white text-sm flex items-center gap-1 transition-colors"
                      >
                        Cambia terapeuta
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="p-4 sm:p-6 mt-3 sm:mt-5">
                    <div className="flex items-start gap-4 sm:gap-6">
                      <div className="w-16 h-16 sm:w-24 sm:h-24 bg-gradient-to-br from-emerald-100 to-teal-50 rounded-full flex items-center justify-center overflow-hidden">
                        {therapist?.photoURL ? (
                          <img 
                            src={therapist.photoURL} 
                            alt={`${therapist.name} ${therapist.surname}`} 
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <User className="w-8 h-8 sm:w-12 sm:h-12 text-emerald-600" />
                        )}
                      </div>
                      <div className="flex-1 mt-2 sm:mt-4">
                        <p className="text-sm sm:text-base text-emerald-800"><span className="font-medium">Nome:</span> {therapist?.name}</p>
                        <p className="text-sm sm:text-base text-emerald-800"><span className="font-medium">Cognome:</span> {therapist?.surname}</p>
                      </div>
                    </div>
                    <Button
                      variant="outline"
                      onClick={() => setIsDetailsModalOpen(true)}
                      className="mt-4 w-full bg-white hover:bg-emerald-50 border-emerald-200 text-emerald-700"
                    >
                      Più dettagli
                    </Button>

                    <TherapistDetailsModal
                      therapist={therapist}
                      open={isDetailsModalOpen}
                      onOpenChange={setIsDetailsModalOpen}
                    />

                    <div className="mt-4 sm:mt-6">
                      <Button
                        onClick={() => setShowBookingSection(!showBookingSection)}
                        className="w-full bg-emerald-600 hover:bg-emerald-700 text-white flex items-center justify-center gap-2"
                      >
                        {showBookingSection ? (
                          <>
                            <ChevronUp className="w-4 h-4" />
                            Chiudi prenotazione
                          </>
                        ) : (
                          <>
                            <Calendar className="w-4 h-4" />
                            Prenota una seduta
                          </>
                        )}
                      </Button>

                      {showBookingSection && (
                        <div className="mt-4">
                          <h3 className="text-base sm:text-lg font-medium text-emerald-800 mb-3 sm:mb-4">Prenota una seduta</h3>
                          <div className="max-w-full overflow-x-auto">
                            <CalendarComponent
                              mode="single"
                              selected={selectedDate}
                              onSelect={setSelectedDate}
                              disabled={(date) => {
                                const now = new Date();
                                const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                                const compareDate = new Date(date);
                                
                                // Disabilita tutte le date passate (incluso oggi se non ci sono slot disponibili)
                                if (compareDate <= today) {
                                  return true;
                                }
                                
                                // Disabilita date oltre 30 giorni
                                const maxDate = new Date(today);
                                maxDate.setDate(today.getDate() + 30);
                                if (compareDate > maxDate) {
                                  return true;
                                }

                                // Disabilita date senza slot disponibili
                                return !hasAvailableSlots(date);
                              }}
                              fromDate={new Date()} // Imposta la data minima a oggi
                              toDate={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)} // Massimo 30 giorni in avanti
                              className="rounded-lg border border-emerald-100 bg-white"
                            />
                          </div>
                          {selectedDate && (
                            <TimeSlotPicker 
                              availableSlots={getAvailableSlotsForDate(selectedDate)} 
                              onSelectTime={bookAppointment} 
                            />
                          )}
                          {bookingError && <p className="text-red-500 text-sm mt-2">{bookingError}</p>}
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>

                <Card className="border-0 bg-white/90">
                  <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                    <CardTitle className="text-base sm:text-lg font-medium">Appuntamenti Prenotati</CardTitle>
                  </CardHeader>
                  <CardContent className="p-0">
                    {appointments.length > 0 ? (
                      <ul className="divide-y divide-emerald-100">
                        {appointments.map((appointment, index) => (
                          <li key={index} className="p-3 sm:p-4 flex justify-between items-center hover:bg-emerald-50/50 transition-colors">
                            <div className="flex items-center gap-3 sm:gap-4">
                              <div className="text-emerald-700">
                                <p className="text-sm sm:text-base font-medium">
                                  {new Date(appointment.date).toLocaleDateString('it-IT')}
                                </p>
                                <p className="text-xs sm:text-sm text-emerald-600">
                                  {appointment.time}
                                </p>
                              </div>
                            </div>
                            <span
                              className={`text-xs sm:text-sm px-2 sm:px-3 py-1 rounded-full ${appointment.status === 'scheduled'
                                  ? 'bg-emerald-100 text-emerald-700'
                                  : 'bg-red-100 text-red-700'
                                }`}
                            >
                              {appointment.status}
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="p-4 text-sm sm:text-base text-emerald-700">Non hai appuntamenti prenotati.</p>
                    )}
                  </CardContent>
                </Card>
              </div>
              <Card className="border-0 bg-white/90 mt-4 sm:mt-6">
                <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                  <CardTitle className="text-base sm:text-lg font-medium flex items-center gap-2">
                    <MessageCircle className="w-4 h-4 sm:w-5 sm:h-5" />
                    Chat con {therapist?.name} {therapist?.surname}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  {renderChat()}
                </CardContent>
              </Card>
            </>
          ) : (
            <Card className="border-0 bg-white/90">
              <CardContent className="p-8 flex flex-col items-center justify-center text-center">
                <div className="w-16 h-16 sm:w-20 sm:h-20 bg-emerald-100 rounded-full flex items-center justify-center mb-4">
                  <Search className="w-8 h-8 sm:w-10 sm:h-10 text-emerald-600" />
                </div>
                <h2 className="text-xl sm:text-2xl font-medium text-emerald-800 mb-3">
                  Stiamo cercando il terapeuta giusto per te
                </h2>
                <p className="text-gray-600 max-w-md">
                  Sulla base delle tue preferenze e necessità, stiamo selezionando il terapeuta più adatto. 
                  Riceverai una notifica non appena avremo trovato il match perfetto.
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <CancelMatchDialog
        open={showCancelDialog}
        onOpenChange={setShowCancelDialog}
        onConfirm={handleCancelMatch}
      />
    </div>
  );
};

export default PatientDashboard;