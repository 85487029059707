import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { User, Save, Plus, Edit2, Trash2 } from 'lucide-react';
import { ref, update, get, onValue } from 'firebase/database';
import { database, auth } from '../../firebaseConfig';

const PatientDetailsModal = ({ patient, open, onOpenChange }) => {
  const [notes, setNotes] = useState([]);
  const [newNoteText, setNewNoteText] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);

  // Carica le note quando il modal viene aperto
  useEffect(() => {
    if (patient?.id && open) {
      const userId = auth.currentUser?.uid;
      const notesRef = ref(database, `therapistNotes/${userId}/${patient.id}`);
      
      const unsubscribe = onValue(notesRef, (snapshot) => {
        if (snapshot.exists()) {
          const notesData = snapshot.val();
          setNotes(Array.isArray(notesData) ? notesData : []);
        } else {
          setNotes([]);
        }
      });

      return () => unsubscribe();
    }
  }, [patient?.id, open]);

  const handleSaveNote = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || !newNoteText.trim() || !patient?.id) return;

      const newNote = {
        id: Date.now().toString(),
        text: newNoteText,
        createdAt: new Date().toISOString()
      };

      const updatedNotes = [...notes, newNote];
      await update(ref(database, `therapistNotes/${userId}`), {
        [patient.id]: updatedNotes
      });

      setNewNoteText('');
    } catch (error) {
      console.error('Errore nel salvare la nota:', error);
      alert('Errore nel salvare la nota');
    }
  };

  const handleUpdateNote = async (noteId, updatedText) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || !patient?.id) return;

      const updatedNotes = notes.map(note => 
        note.id === noteId ? { ...note, text: updatedText } : note
      );

      await update(ref(database, `therapistNotes/${userId}`), {
        [patient.id]: updatedNotes
      });

      setEditingNoteId(null);
    } catch (error) {
      console.error('Errore nell\'aggiornare la nota:', error);
      alert('Errore nell\'aggiornare la nota');
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || !patient?.id) return;

      const updatedNotes = notes.filter(note => note.id !== noteId);
      await update(ref(database, `therapistNotes/${userId}`), {
        [patient.id]: updatedNotes
      });
    } catch (error) {
      console.error('Errore nell\'eliminare la nota:', error);
      alert('Errore nell\'eliminare la nota');
    }
  };

  if (!patient) return null;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto bg-white">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-emerald-800">
            Dettagli del paziente
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          {/* Informazioni principali */}
          <div className="flex items-center gap-6 mb-8">
            <div className="w-24 h-24 bg-gray-100 rounded-full overflow-hidden">
              {patient.photoURL ? (
                <img 
                  src={patient.photoURL} 
                  alt="Profile" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <User className="w-12 h-12 text-gray-400" />
                </div>
              )}
            </div>
            <div className='gap-1'>
              <div>
                <p className="text-sm text-gray-600 mb-2">
                  <span className='font-semibold'>Nome e cognome:</span> {patient.name} {patient.surname}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">
                  <span className='font-semibold'>Email:</span> {patient.email}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600">
                  <span className='font-semibold'>Fascia d'età:</span> {
                    patient.ageRange === 'under18' ? 'Sotto i 18 anni' :
                    patient.ageRange === '18-25' ? '18-25 anni' :
                    patient.ageRange === '26-35' ? '26-35 anni' :
                    patient.ageRange === 'over35' ? 'Oltre 35 anni' : 'Non specificato'
                  }
                </p>
              </div>
            </div>
          </div>

          {/* Preferenze terapia */}
          <section>
            <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">
              Preferenze per la terapia
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-700">
                  <span className="font-semibold">Tipo di terapia:</span> {
                    patient.therapyType === 'individual' ? 'Individuale' :
                    patient.therapyType === 'couple' ? 'Di coppia' :
                    patient.therapyType === 'family' ? 'Familiare' : 'Non specificato'
                  }
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700">
                  <span className="font-semibold">Fascia oraria preferita:</span> {
                    patient.preferredTime === 'morning' ? 'Mattina' :
                    patient.preferredTime === 'afternoon' ? 'Pomeriggio' :
                    patient.preferredTime === 'evening' ? 'Sera' : 'Non specificato'
                  }
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700">
                  <span className="font-semibold">Preferenza terapeuta:</span> {
                    patient.preferredGender === 'male' ? 'Uomo' :
                    patient.preferredGender === 'female' ? 'Donna' : 'Nessuna preferenza'
                  }
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700">
                  <span className="font-semibold">Età terapeuta:</span> {
                    patient.therapistAgePreference === 'under35' ? 'Sotto i 35 anni' :
                    patient.therapistAgePreference === 'over35' ? 'Oltre i 35 anni' : 'Nessuna preferenza'
                  }
                </p>
              </div>
            </div>
          </section>

          {/* Problematiche specifiche */}
          {patient.specificIssues && (
            <section>
              <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">
                Problematiche specifiche
              </h3>
              <p className="text-sm text-gray-700 leading-relaxed">
                {patient.specificIssues}
              </p>
            </section>
          )}

          {/* Motivazioni */}
          {patient.reasons && (
            <section>
              <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">
                Motivazioni per la terapia
              </h3>
              <p className="text-sm text-gray-700 leading-relaxed">
                {patient.reasons}
              </p>
            </section>
          )}

          {/* Note del terapeuta */}
          <section className="border-t mt-6 pt-6">
            <h3 className="text-lg font-medium mb-4 text-emerald-800 font-semibold">
              Note personali
            </h3>
            
            <div className="space-y-4">
              {/* Lista delle note esistenti */}
              {notes.map(note => (
                <div key={note.id} className="bg-gray-50 rounded-lg p-4">
                  {editingNoteId === note.id ? (
                    <div className="space-y-2">
                      <textarea
                        value={note.text}
                        onChange={(e) => {
                          const updatedNotes = notes.map(n =>
                            n.id === note.id ? { ...n, text: e.target.value } : n
                          );
                          setNotes(updatedNotes);
                        }}
                        className="w-full p-2 border rounded text-sm"
                        rows="3"
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => handleUpdateNote(note.id, note.text)}
                          className="bg-emerald-500 text-white rounded px-3 py-1 text-sm hover:bg-emerald-600"
                        >
                          Salva
                        </button>
                        <button
                          onClick={() => setEditingNoteId(null)}
                          className="bg-gray-200 text-gray-700 rounded px-3 py-1 text-sm hover:bg-gray-300"
                        >
                          Annulla
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex justify-between items-start mb-2">
                        <p className="text-sm text-gray-700">{note.text}</p>
                        <div className="flex gap-2 ml-4">
                          <button
                            onClick={() => setEditingNoteId(note.id)}
                            className="text-gray-500 hover:text-emerald-600"
                          >
                            <Edit2 className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => handleDeleteNote(note.id)}
                            className="text-gray-500 hover:text-red-600"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                      <p className="text-xs text-gray-400">
                        {new Date(note.createdAt).toLocaleDateString('it-IT', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </p>
                    </div>
                  )}
                </div>
              ))}

              {/* Form per aggiungere nuova nota */}
              <div className="space-y-2">
                <textarea
                  value={newNoteText}
                  onChange={(e) => setNewNoteText(e.target.value)}
                  placeholder="Scrivi una nuova nota..."
                  className="w-full p-3 border rounded-lg text-sm text-gray-700 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                  rows="3"
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleSaveNote}
                    disabled={!newNoteText.trim()}
                    className="inline-flex items-center gap-2 bg-emerald-500 text-white rounded-lg px-4 py-2 text-sm hover:bg-emerald-600 transition-colors disabled:opacity-50"
                  >
                    <Plus className="w-4 h-4" />
                    Aggiungi nota
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* Data di registrazione */}
          {patient.submittedAt && (
            <div className="text-xs text-gray-500 text-right">
              Registrato il: {formatDate(patient.submittedAt)}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PatientDetailsModal; 